<template>
  <div class="main-container">
    <header class="header">
      <h1 class="artistic-title">阴阳思维导图</h1>
    </header>
    <div class="content-wrapper">
      <div class="circles-container" @click="clearFocus">
        <div v-for="(circle, circleIndex) in circles" :key="circleIndex" 
             class="circle" 
             :style="getCircleStyle(circleIndex)" 
             @click.stop="toggleFocusCircle(circleIndex)"
             :class="{ 'focused': circle.isFocused }">
          <div class="center-small-circle">
            <div class="small-circle" :style="{ borderColor: currentColor(circle) }">
              <input
                v-model="circle.centerText"
                @focus="circle.centerIsFocused = true"
                @blur="circle.centerIsFocused = false"
                :class="{ 'focused': circle.centerIsFocused }"
                class="small-circle-input"
                type="text"
              >
            </div>
          </div>
          <div v-for="(smallCircle, index) in circle.smallCircles" :key="index" 
               class="small-circle-wrapper" 
               :style="getSmallCircleStyle(index, circle.smallCircles.length, circleIndex)">
            <div class="small-circle" :style="{ borderColor: currentColor(circle) }">
              <input
                v-model="smallCircle.text"
                @focus="smallCircle.isFocused = true"
                @blur="smallCircle.isFocused = false"
                :class="{ 'focused': smallCircle.isFocused }"
                class="small-circle-input"
                type="text"
              >
            </div>
          </div>
        </div>

        <svg class="arrows-svg" :width="containerSize.width" :height="containerSize.height">
          <g v-for="(arrow, index) in allArrows" :key="index" 
             @click.stop="toggleArrowColor(index)"
             @dblclick.stop="reverseArrowDirection(index)">
            <path :d="getArrowPath(arrow)" :fill="arrow.color" />
            <line :x1="arrow.x1" :y1="arrow.y1" :x2="arrow.x2" :y2="arrow.y2"
                  :stroke="arrow.color" stroke-width="4" />
          </g>
        </svg>
      </div>
      <div class="sidebar">
        <div class="sidebar-section">
          <h3>颜色控制</h3>
          <button @click="changeColor" class="sidebar-button">改变颜色</button>
        </div>
        <div class="sidebar-section">
          <h3>轮控制</h3>
          <button @click="addLargeCircle" class="sidebar-button">添加轮</button>
          <button @click="removeLargeCircle" class="sidebar-button" :disabled="circles.length <= 1">删除轮</button>
          <button @click="toggleDashedBorder" class="sidebar-button" :disabled="!focusedCircle">切换边框：虚线/实线 </button>
        </div>
        <div class="sidebar-section">
          <h3>文件操作</h3>
          <button @click="saveAsXML" class="sidebar-button" title="保存图表为 XML 文件">
            <i class="fas fa-save"></i> 保存
          </button>
          <button @click="openXMLFile" class="sidebar-button" title="打开 XML 文件">
            <i class="fas fa-folder-open"></i> 打开
          </button>
        </div>
      </div>
    </div>
    <div v-if="showSaveMessage" class="save-message">保存成功！</div>
    <input type="file" ref="fileInput" style="display: none" @change="handleFileSelected" accept=".xml">
  </div>
</template>

<script>
export default {
  name: 'CircleShape',
  data() {
    return {
      colors: [
        '#FF6B6B', // 鲜红
        '#4ECDC4', // 青绿
        '#45B7D1', // 天蓝
        '#FFA07A', // 浅鲑鱼色
        '#98D8C8', // 薄荷绿
        '#F06292', // 粉红
        '#AED581', // 黄绿
        '#7E57C2', // 深紫
        '#FFD54F', // 琥珀色
        '#4DB6AC', // 蓝绿
        '#FF7043', // 深橙
        '#66BB6A', // 绿色
        '#5C6BC0', // 靛蓝
        '#FFA726', // 橙色
        '#26A69A', // 蓝绿色
        '#EC407A', // 玫瑰红
        '#7CB342', // 酸橙绿
        '#8D6E63', // 棕色
        '#29B6F6', // 浅蓝
        '#AB47BC'  // 紫色
      ],
      circles: [{
        currentColorIndex: 0,
        centerText: '',
        centerIsFocused: false,
        smallCircles: [
          { text: '', isFocused: false },
          { text: '', isFocused: false },
          { text: '', isFocused: false },
          { text: '', isFocused: false }
        ],
        size: 200,
        isFocused: false,
        rotation: 0,
        isDashed: false
      }],
      containerSize: { width: 0, height: 0 },
      arrows: [], // 外部箭头
      innerArrows: [], // 内部箭头
      showSaveMessage: false,
      nextUniqueId: 1,
    }
  },
  methods: {
    changeColor() {
      if (this.focusedCircle) {
        // 如果有选中的圆，改变选中圆的颜色
        this.focusedCircle.currentColorIndex = (this.focusedCircle.currentColorIndex + 1) % this.colors.length;
      } else {
        // 如果没有选中的圆，改变最外层圆的颜色
        const outerCircle = this.circles[this.circles.length - 1];
        outerCircle.currentColorIndex = (outerCircle.currentColorIndex + 1) % this.colors.length;
      }
    },
    getCircleStyle(circleIndex) {
      const circle = this.circles[circleIndex];
      const centerX = this.containerSize.width / 2;
      const centerY = this.containerSize.height / 2;
      
      return {
        borderColor: this.currentColor(circle),
        borderStyle: circle.isDashed ? 'dashed' : 'solid',
        width: `${circle.size}px`,
        height: `${circle.size}px`,
        borderWidth: '1px',
        position: 'absolute',
        top: `${centerY - circle.size / 2}px`,
        left: `${centerX - circle.size / 2}px`,
        zIndex: this.circles.length - circleIndex,
        boxShadow: circle.isFocused ? '0 0 20px rgba(66, 185, 131, 0.7)' : 'none'
      }
    },
    getSmallCircleStyle(index, count, circleIndex) {
      const circle = this.circles[circleIndex];
      const angle = (index * (360 / count)) - 90 + circle.rotation;
      const radius = circle.size / 2; // 使用大圆的半径
      const x = Math.cos(angle * Math.PI / 180) * radius;
      const y = Math.sin(angle * Math.PI / 180) * radius;
      return {
        transform: `translate(${x}px, ${y}px)`,
        width: '60px',
        height: '60px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-30px',
        marginLeft: '-30px'
      }
    },
    toggleFocusCircle(index) {
      this.circles.forEach((circle, i) => {
        circle.isFocused = i === index;
      });
    },
    clearFocus() {
      this.circles.forEach(circle => {
        circle.isFocused = false;
      });
    },
    addSmallCircle() {
      if (this.focusedCircle) {
        this.focusedCircle.smallCircles.push({ text: '', isFocused: false, id: this.generateUniqueId() });
      }
    },
    removeSmallCircle() {
      if (this.focusedCircle && this.focusedCircle.smallCircles.length > 1) {
        this.focusedCircle.smallCircles.pop();
      }
    },
    addLargeCircle() {
      const newSize = this.circles[this.circles.length - 1].size + 100;
      this.circles.push({
        currentColorIndex: 0,
        centerText: '', // 新增中心小圆的文本
        centerIsFocused: false, // 新增中心小圆的焦点状态
        centerId: this.generateUniqueId(), // 为中心小圆添加唯一 ID
        smallCircles: [
          { text: '', isFocused: false, id: this.generateUniqueId() },
          { text: '', isFocused: false, id: this.generateUniqueId() },
          { text: '', isFocused: false, id: this.generateUniqueId() },
          { text: '', isFocused: false, id: this.generateUniqueId() }
        ],
        size: newSize,
        isFocused: false,
        rotation: (this.circles.length * 30) % 360,
        isDashed: false
      });
      this.$nextTick(() => {
        this.calculateArrows();
      });
    },
    removeLargeCircle() {
      if (this.circles.length > 1) {
        this.circles.pop();
      }
      this.$nextTick(() => {
        this.calculateArrows();
      });
    },
    toggleDashedBorder() {
      if (this.focusedCircle) {
        this.focusedCircle.isDashed = !this.focusedCircle.isDashed;
      }
    },
    updateContainerSize() {
      this.containerSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      this.$nextTick(() => {
        this.calculateArrows();
      });
    },
    calculateArrows() {
      const newArrows = [];
      const newInnerArrows = [];

      // 计算外部箭头
      for (let i = 0; i < this.circles.length - 1; i++) {
        const currentCircle = this.circles[i];
        const nextCircle = this.circles[i + 1];
        for (let j = 0; j < currentCircle.smallCircles.length; j++) {
          const startCenter = this.getSmallCirclePosition(i, j, true);
          const endCenter = this.getSmallCirclePosition(i + 1, j, true);
          const existingArrow = this.arrows.find(arrow => 
            arrow.startId === currentCircle.smallCircles[j].id && 
            arrow.endId === nextCircle.smallCircles[j].id
          );
          newArrows.push(this.createOrUpdateArrow(startCenter, endCenter, currentCircle.smallCircles[j].id, nextCircle.smallCircles[j].id, existingArrow));
        }
      }

      // 计算内部箭头（仅针对第一个圆）
      if (this.circles.length > 0) {
        const firstCircle = this.circles[0];
        const centerX = this.containerSize.width / 2;
        const centerY = this.containerSize.height / 2;
        for (let j = 0; j < firstCircle.smallCircles.length; j++) {
          const end = this.getSmallCirclePosition(0, j, true);
          const existingArrow = this.innerArrows.find(arrow => 
            arrow.startId === firstCircle.centerId && 
            arrow.endId === firstCircle.smallCircles[j].id
          );
          newInnerArrows.push(this.createOrUpdateArrow({x: centerX, y: centerY}, end, firstCircle.centerId, firstCircle.smallCircles[j].id, existingArrow));
        }
      }

      this.arrows = newArrows;
      this.innerArrows = newInnerArrows;
    },
    createOrUpdateArrow(start, end, startId, endId, existingArrow) {
      const smallCircleRadius = 30;
      const dx = end.x - start.x;
      const dy = end.y - start.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const unitDx = dx / distance;
      const unitDy = dy / distance;

      const newArrow = {
        x1: start.x + unitDx * smallCircleRadius,
        y1: start.y + unitDy * smallCircleRadius,
        x2: end.x - unitDx * smallCircleRadius,
        y2: end.y - unitDy * smallCircleRadius,
        color: existingArrow ? existingArrow.color : '#42b983',
        isReversed: existingArrow ? existingArrow.isReversed : false,
        startId: startId,
        endId: endId
      };

      if (existingArrow && existingArrow.isReversed) {
        [newArrow.x1, newArrow.x2] = [newArrow.x2, newArrow.x1];
        [newArrow.y1, newArrow.y2] = [newArrow.y2, newArrow.y1];
      }

      return newArrow;
    },
    getSmallCirclePosition(circleIndex, smallCircleIndex, getCenter = false) {
      const circle = this.circles[circleIndex];
      const centerX = this.containerSize.width / 2;
      const centerY = this.containerSize.height / 2;
      const angle = (smallCircleIndex * (360 / circle.smallCircles.length)) - 90 + circle.rotation;
      const radius = circle.size / 2;
      const smallCircleRadius = 30; // 小圆的半径，根据实际情况调整
      
      // 计算小圆中心的位置
      const centerSmallX = centerX + Math.cos(angle * Math.PI / 180) * radius;
      const centerSmallY = centerY + Math.sin(angle * Math.PI / 180) * radius;
      
      if (getCenter) {
        return { x: centerSmallX, y: centerSmallY };
      }
      
      // 计算小圆边缘的位置
      const edgeX = centerSmallX + Math.cos(angle * Math.PI / 180) * smallCircleRadius;
      const edgeY = centerSmallY + Math.sin(angle * Math.PI / 180) * smallCircleRadius;
      
      return { x: edgeX, y: edgeY };
    },
    toggleArrowColor(index) {
      const arrow = this.allArrows[index];
      arrow.color = arrow.color === '#42b983' ? '#e74c3c' : '#42b983';
    },
    reverseArrowDirection(index) {
      const arrow = this.allArrows[index];
      // 交换起点和终点
      [arrow.x1, arrow.x2] = [arrow.x2, arrow.x1];
      [arrow.y1, arrow.y2] = [arrow.y2, arrow.y1];
      arrow.isReversed = !arrow.isReversed;
    },
    getArrowPath(arrow) {
      const dx = arrow.x2 - arrow.x1;
      const dy = arrow.y2 - arrow.y1;
      const angle = Math.atan2(dy, dx);

      // 箭头头部的大小
      const arrowHeadLength = 15;
      const arrowHeadWidth = 7.5;

      // 计算箭头头部的点
      const x2 = arrow.x2 - arrowHeadLength * Math.cos(angle);
      const y2 = arrow.y2 - arrowHeadLength * Math.sin(angle);
      const x3 = x2 - arrowHeadWidth * Math.cos(angle - Math.PI / 2);
      const y3 = y2 - arrowHeadWidth * Math.sin(angle - Math.PI / 2);
      const x4 = x2 - arrowHeadWidth * Math.cos(angle + Math.PI / 2);
      const y4 = y2 - arrowHeadWidth * Math.sin(angle + Math.PI / 2);

      return `M${arrow.x2},${arrow.y2} L${x3},${y3} L${x4},${y4} Z`;
    },
    getSmallCircleCoordinates(index, count, circleIndex) {
      const circle = this.circles[circleIndex];
      const angle = (index * (360 / count)) - 90 + circle.rotation;
      const radius = (circle.size / 2) - 30; // 减去小圆的半径
      const x = (circle.size / 2) + Math.cos(angle * Math.PI / 180) * radius;
      const y = (circle.size / 2) + Math.sin(angle * Math.PI / 180) * radius;
      return { x, y };
    },
    getInnerArrowPath(index, count, circleIndex) {
      const circle = this.circles[circleIndex];
      const end = this.getSmallCircleCoordinates(index, count, circleIndex);
      const angle = Math.atan2(end.y - circle.size / 2, end.x - circle.size / 2);
      
      // 箭头头部的大小
      const arrowHeadLength = 10;
      const arrowHeadWidth = 5;

      // 计算箭头头部的点
      const x2 = end.x - arrowHeadLength * Math.cos(angle);
      const y2 = end.y - arrowHeadLength * Math.sin(angle);
      const x3 = x2 - arrowHeadWidth * Math.cos(angle - Math.PI / 2);
      const y3 = y2 - arrowHeadWidth * Math.sin(angle - Math.PI / 2);
      const x4 = x2 - arrowHeadWidth * Math.cos(angle + Math.PI / 2);
      const y4 = y2 - arrowHeadWidth * Math.sin(angle + Math.PI / 2);

      return `M${end.x},${end.y} L${x3},${y3} L${x4},${y4} Z`;
    },
    saveAsXML() {
      const xmlContent = this.generateXML();
      const blob = new Blob([xmlContent], { type: 'text/xml' });
      
      // 创建一个临时的 URL
      const url = URL.createObjectURL(blob);
      
      // 创建一个隐藏的 <a> 元素
      const link = document.createElement('a');
      link.href = url;
      
      // 提示用户输入文件名
      const fileName = prompt('请输入文件名', 'circles_data.xml');
      
      if (fileName) {
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // 显示保存成功消息
        this.showSaveMessage = true;
        setTimeout(() => {
          this.showSaveMessage = false;
        }, 3000); // 3秒后隐藏消息
      }
      
      // 释放 URL 对象
      URL.revokeObjectURL(url);
    },
    generateXML() {
      let xml = '<?xml version="1.0" encoding="UTF-8"?>\n<diagram>\n';
      
      // 保存圆形数据
      xml += '  <circles>\n';
      this.circles.forEach((circle, index) => {
        xml += `    <circle index="${index}" size="${circle.size}" rotation="${circle.rotation}" isDashed="${circle.isDashed}" colorIndex="${circle.currentColorIndex}">\n`;
        xml += `      <centerText id="${circle.centerId}">${this.escapeXML(circle.centerText)}</centerText>\n`;
        xml += '      <smallCircles>\n';
        
        circle.smallCircles.forEach((smallCircle, smallIndex) => {
          xml += `        <smallCircle index="${smallIndex}" id="${smallCircle.id}">\n`;
          xml += `          <text>${this.escapeXML(smallCircle.text)}</text>\n`;
          xml += '        </smallCircle>\n';
        });
        
        xml += '      </smallCircles>\n';
        xml += '    </circle>\n';
      });
      xml += '  </circles>\n';
      
      // 保存箭头数据
      xml += '  <arrows>\n';
      this.allArrows.forEach((arrow, index) => {
        xml += `    <arrow index="${index}">\n`;
        xml += `      <x1>${arrow.x1}</x1>\n`;
        xml += `      <y1>${arrow.y1}</y1>\n`;
        xml += `      <x2>${arrow.x2}</x2>\n`;
        xml += `      <y2>${arrow.y2}</y2>\n`;
        xml += `      <color>${arrow.color}</color>\n`;
        xml += `      <isReversed>${arrow.isReversed}</isReversed>\n`;
        xml += `      <startId>${arrow.startId}</startId>\n`;
        xml += `      <endId>${arrow.endId}</endId>\n`;
        xml += '    </arrow>\n';
      });
      xml += '  </arrows>\n';
      
      xml += '</diagram>';
      return xml;
    },
    escapeXML(unsafe) {
      return unsafe.replace(/[<>&'"]/g, function (c) {
        switch (c) {
          case '<': return '&lt;';
          case '>': return '&gt;';
          case '&': return '&amp;';
          case "'": return '&apos;';
          case '"': return '&quot;';
        }
      });
    },
    generateUniqueId() {
      return this.nextUniqueId++;
    },
    openXMLFile() {
      this.$refs.fileInput.click();
    },
    handleFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const xmlContent = e.target.result;
          this.parseXMLAndRender(xmlContent);
        };
        reader.readAsText(file);
      }
    },
    parseXMLAndRender(xmlContent) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
      
      // 清除现有数据
      this.circles = [];
      this.arrows = [];
      this.innerArrows = [];

      // 解析圆形数据
      const circleElements = xmlDoc.getElementsByTagName("circle");
      for (let i = 0; i < circleElements.length; i++) {
        const circleEl = circleElements[i];
        const circle = {
          size: parseInt(circleEl.getAttribute("size")),
          rotation: parseFloat(circleEl.getAttribute("rotation")),
          isDashed: circleEl.getAttribute("isDashed") === "true",
          currentColorIndex: parseInt(circleEl.getAttribute("colorIndex")),
          centerText: circleEl.getElementsByTagName("centerText")[0].textContent,
          centerId: circleEl.getElementsByTagName("centerText")[0].getAttribute("id"),
          smallCircles: [],
          isFocused: false,
          centerIsFocused: false
        };

        const smallCircleElements = circleEl.getElementsByTagName("smallCircle");
        for (let j = 0; j < smallCircleElements.length; j++) {
          const smallCircleEl = smallCircleElements[j];
          circle.smallCircles.push({
            text: smallCircleEl.getElementsByTagName("text")[0].textContent,
            id: smallCircleEl.getAttribute("id"),
            isFocused: false
          });
        }

        this.circles.push(circle);
      }

      // 解析箭头数据
      const arrowElements = xmlDoc.getElementsByTagName("arrow");
      for (let i = 0; i < arrowElements.length; i++) {
        const arrowEl = arrowElements[i];
        const arrow = {
          x1: parseFloat(arrowEl.getElementsByTagName("x1")[0].textContent),
          y1: parseFloat(arrowEl.getElementsByTagName("y1")[0].textContent),
          x2: parseFloat(arrowEl.getElementsByTagName("x2")[0].textContent),
          y2: parseFloat(arrowEl.getElementsByTagName("y2")[0].textContent),
          color: arrowEl.getElementsByTagName("color")[0].textContent,
          isReversed: arrowEl.getElementsByTagName("isReversed")[0].textContent === "true",
          startId: arrowEl.getElementsByTagName("startId")[0].textContent,
          endId: arrowEl.getElementsByTagName("endId")[0].textContent
        };

        // 根据箭头的起点和终点决定是内部箭头还是外部箭头
        if (this.isInnerArrow(arrow)) {
          this.innerArrows.push(arrow);
        } else {
          this.arrows.push(arrow);
        }
      }

      // 重新计算箭头位置
      this.$nextTick(() => {
        this.calculateArrows();
      });
    },
    isInnerArrow(arrow) {
      // 如果箭头的起点ID等于第一个圆的中心ID，则认为是内部箭头
      return arrow.startId === this.circles[0].centerId;
    },
  },
  computed: {
    currentColor() {
      return circle => this.colors[circle.currentColorIndex]
    },
    focusedCircle() {
      return this.circles.find(circle => circle.isFocused);
    },
    allArrows() {
      return [...this.arrows, ...this.innerArrows];
    }
  },
  mounted() {
    this.updateContainerSize();
    window.addEventListener('resize', this.updateContainerSize);
    this.$nextTick(() => {
      this.calculateArrows();
    });
    this.circles.forEach(circle => {
      circle.centerId = this.generateUniqueId();
      circle.smallCircles.forEach(smallCircle => {
        smallCircle.id = this.generateUniqueId();
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateContainerSize);
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.header {
  background-color: #42b983; /* 使用一个显眼的绿色背景 */
  padding: 15px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 添加一些阴影效果 */
}

.artistic-title {
  text-align: center;
  margin: 0;
  color: white; /* 将文字颜色改为白色，以便在深色背景上更加醒目 */
  font-size: 28px; /* 增大字体大小 */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2); /* 添加文字阴影效果 */
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.circles-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.sidebar {
  width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
}

.sidebar-section {
  margin-bottom: 20px;
}

.sidebar-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-button:hover {
  background-color: #3aa876;
}

.sidebar-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.circle {
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  z-index: 5; /* 确保大圆在箭头下方 */
}

.circle-text {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.small-circle-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15; /* 确保小圆在箭头之上 */
}

.small-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 5px solid;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.small-circle-input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 12px;
  color: #333;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}

.small-circle-input.focused {
  background-color: rgba(255, 255, 255, 0.8);
}

.button-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20; /* 确保按钮在最上层 */
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.color-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px;
}

.color-button:hover {
  background-color: #3aa876;
}

.color-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.arrows-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.arrows-svg g {
  cursor: pointer;
  pointer-events: auto;
}

/* 确保其他元素在箭头之上 */
.circle,
.small-circle-wrapper,
.button-container {
  z-index: 2;
  position: relative;
}

.debug-info {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 12px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  border-radius: 3px;
}

.debug-button {
  position: absolute;
  top: 30px;
  left: 5px;
  font-size: 12px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 2px 5px;
  cursor: pointer;
  z-index: 1000;
}

.circle-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}

/* 确保其他元素在覆盖层之上 */
.circle-text,
.debug-info,
.debug-button,
.small-circle-wrapper {
  z-index: 2;
  position: relative;
}

.center-small-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.inner-arrows-svg {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.save-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button i {
  margin-right: 5px;
}

.save-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

.open-button {
  background-color: #3498db;
}

.open-button:hover {
  background-color: #2980b9;
}
</style>