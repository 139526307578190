import { createRouter, createWebHistory } from 'vue-router'
import Circle from '../components/Circle.vue'

const routes = [
  {
    path: '/',
    name: 'Circle',
    component: Circle
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router